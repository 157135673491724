import React, { useEffect, useRef, useState } from "react";

import format from "date-fns/format";
import parse from "date-fns/parse";
import { Input, InputValidation } from "./";

interface DateInputProps {
    label: any;
    placeholder: string;
    setFieldValue: (field: string, value: Date) => void;
    onBlur: (e: React.FormEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    validation?: InputValidation;
    autoComplete?: string;
    name: string;
    defaultValue?: Date;
    required?: boolean;
    className?: string;
}

const PARSE_FORMAT = "yyyy-MM-dd" as const;

const toDate = (date: string): Date => {
    return parse(date, PARSE_FORMAT, new Date());
};

const isDate = (date: unknown): date is Date => {
    if (typeof date === "string") {
        try {
            const parsed = toDate(date);
            return parsed instanceof Date && !isNaN(parsed.getTime());
        } catch {
            return false;
        }
    }
    return date instanceof Date;
};

const initialValue = (date: Date | undefined) => {
    if (date && isDate(date)) {
        return format(date, PARSE_FORMAT);
    }
    return "";
};

const DateInput = (props: DateInputProps) => {
    const [value, setValue] = useState<string>(
        initialValue(props.defaultValue),
    );
    const nameRef = useRef(props.name);
    const setFieldValue = useRef(props.setFieldValue);
    useEffect(() => {
        if (isDate(value)) {
            setFieldValue.current(nameRef.current, toDate(value));
        }
    }, [value]);
    const onBlur = (e: React.FormEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        if (!isDate(value)) {
            setValue(initialValue(props.defaultValue));
        }
        props.onBlur(e);
    };
    return (
        <Input
            label={props.label}
            placeholder={props.placeholder}
            type="date"
            name={props.name}
            value={value}
            onChange={(event) => setValue(event.currentTarget.value)}
            onBlur={onBlur}
            validation={props.validation}
            required={props.required}
            className={props.className}
        />
    );
};

export default DateInput;
