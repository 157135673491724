import styled from "styled-components/macro";

import {
    border,
    colors,
    defaultRadius,
    distances,
    palette,
} from "../../styles/constants";

const ButtonExternalLink = styled.a`
    padding: 10px 16px;
    border-radius: ${defaultRadius};
    color: white;
    background: ${palette.primary[500]};
    border: ${border.normal} solid ${palette.primary[500]};
    text-decoration: none;
    user-select: none;
    font-weight: 500;
    font-size: 14px;
    line-height: ${distances.small};
    cursor: pointer;
    min-height: 40px;
    display: inline-block;
    text-align: center;

    line-height: 20px;

    &:hover {
        background: ${palette.primary[600]};
        border: ${border.normal} solid ${palette.primary[600]};
    }

    &:focus {
        outline: none;
    }

    &:disabled {
        background: ${palette.primary[300]};
        border-color: ${palette.primary[300]};
        cursor: not-allowed;
    }

    &.tiny {
        min-width: auto;
        padding: 0;
        height: ${distances.normal};
        padding-left: ${distances.tiny};
        padding-right: ${distances.tiny};
    }

    &.tiny-768 {
        @media (max-width: 768px) {
            min-width: auto;
            padding: 0;
            height: ${distances.normal};
            padding-left: ${distances.tiny};
            padding-right: ${distances.tiny};
        }
    }

    &.stretch {
        min-width: 100%;
    }

    &.stretch-768 {
        @media (max-width: 768px) {
            min-width: 100%;
        }
    }

    &.with-icon {
        padding-top: 3px;
        padding-bottom: 1px;
        padding-left: 1px;
    }

    &.small {
        padding: 6px 12px;
        font-size: 12px;
        min-height: 20px;
    }

    &.alt {
        background: ${palette.primary[50]};
        color: ${palette.primary[500]};
        border: ${border.normal} solid ${palette.primary[50]};

        &:hover {
            background: ${palette.primary[100]};
            border: ${border.normal} solid ${palette.primary[100]};
        }
        &:focus {
            border: ${border.normal} solid ${palette.primary[300]};
        }
        &:active {
            background: ${palette.primary[300]};
            color: ${palette.primary[600]};
        }

        &:disabled {
          opacity: .5;
          pointer-events: none;
        }
    }

    &.red {
        background: ${palette.destructive[500]};
        border: ${border.normal} solid ${palette.destructive[500]};

        &:hover {
            background: ${palette.destructive[600]};
            border: ${border.normal} solid ${palette.destructive[600]};
        }
        &:active {
            background: ${palette.destructive[700]};
            border: ${border.normal} solid ${palette.destructive[700]};
        }
    }

    &.outlined {
        background: transparent;
        color: ${palette.primary[500]};
        border: ${border.normal} solid ${palette.primary[500]};

        &:hover {
            background: transparent;
            border: ${border.normal} solid ${palette.primary[400]};
        }
        &:focus {
            border: ${border.normal} solid ${palette.primary[400]};
        }
        &:active {
            background: transparent;
            color: ${palette.primary[400]};
            border: ${border.normal} solid ${palette.primary[400]};
        }

        &:disabled {
          opacity: .5;
          pointer-events: none;
        }

        &.red {
            background: transparent;
            color: ${palette.destructive[500]};
            border: ${border.normal} solid ${palette.destructive[500]};

            &:hover {
                background: transparent;
                border: ${border.normal} solid ${palette.destructive[600]};
            }
            &:active {
                background: transparent;
                color: ${palette.destructive[700]};
                border: ${border.normal} solid ${palette.destructive[700]};
            }
        }
    }

    &.icon {
        background: transparent;
        color: ${palette.primary[500]};
        border: ${border.normal} solid transparent;

        &:hover {
            background: ${palette.primary[100]};
            border: ${border.normal} solid ${palette.primary[100]};
        }
        &:focus {
            border: ${border.normal} solid ${palette.primary[300]};
        }
        &:active {
            background: ${palette.primary[300]};
            color: ${palette.primary[600]};
        }

        &:disabled {
          opacity: .5;
          pointer-events: none;
        }

        &.red {
            color: ${palette.destructive[500]};

            &:hover {
                background: ${palette.destructive[100]};
                border: ${border.normal} solid ${palette.destructive[100]};
            }
            &:focus {
                border: ${border.normal} solid ${palette.destructive[300]};
            }
            &:active {
                background: ${palette.destructive[300]};
                color: ${palette.destructive[600]};
            }
        }
    }

    &.top-bar {
        display: flex;
        gap: ${distances.tiny};
        color: ${palette.neutral[600]};
        align-items: center;
        padding: 4px;
        min-height: unset;

        &:hover {
            color: ${palette.neutral[800]};
            background: white;
            border: ${border.normal} solid white;
        }
        &:focus {
            color: ${palette.neutral[800]};
            background: white;
            border: ${border.normal} solid white;
        }
        &:active, &[data-expanded='true'] {
            color: ${palette.neutral[900]};
            background: white;
            border: ${border.normal} solid white;
        }
    }

    &.slim {
        padding: 10px;
    }

    &.bg-white {
        background: white;
    }

    &.has-icons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: ${distances.tiny};
    }
`;

export default ButtonExternalLink;
